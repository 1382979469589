import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import { ModalController, AlertController, NavController, LoadingController, ToastController, Events } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
//import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { LabelService } from '../label/label.service';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { ConfigService } from '../config/config.service';
import { convertSnaps } from '../db-utils';
import { first, map } from 'rxjs/operators';
import { MultiRegionService } from '../multi-region/multi-region.service';
import { UtilsService } from '../utils/utils.service';
import { VendorService } from '../vendor/vendor.service';
import { HomePage } from 'src/app/home/home.page';
import { SharedNewService } from '../shared-new/shared-new.service';
import { MultiCountriesService } from '../multi-countries/multi-countries.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic/storage";
import * as i3 from "@ionic-native/social-sharing/ngx/index";
import * as i4 from "../label/label.service";
import * as i5 from "../loggly-logger/loggly-logger.service";
import * as i6 from "@angular/fire/firestore";
import * as i7 from "../config/config.service";
import * as i8 from "../multi-region/multi-region.service";
import * as i9 from "../utils/utils.service";
import * as i10 from "../vendor/vendor.service";
import * as i11 from "../multi-countries/multi-countries.service";
import * as i12 from "../shared-new/shared-new.service";
var SharedService = /** @class */ (function () {
    function SharedService(modalController, storage, alertController, socialSharing, 
    //private firebaseDynamicLinks: FirebaseDynamicLinks,
    navCtrl, labelService, logglyService, afs, configService, events, 
    //private multiRegionService: MultiRegionService,
    loadingController, toastController, multiRegionService, utilsService, vendorService, multiCountriesService, sharedNewService) {
        this.modalController = modalController;
        this.storage = storage;
        this.alertController = alertController;
        this.socialSharing = socialSharing;
        this.navCtrl = navCtrl;
        this.labelService = labelService;
        this.logglyService = logglyService;
        this.afs = afs;
        this.configService = configService;
        this.events = events;
        this.loadingController = loadingController;
        this.toastController = toastController;
        this.multiRegionService = multiRegionService;
        this.utilsService = utilsService;
        this.vendorService = vendorService;
        this.multiCountriesService = multiCountriesService;
        this.sharedNewService = sharedNewService;
        this.referrer = { userId: '' };
        this.isPromoPopupViewed = false;
        this.sharedLinkRouted = false;
    }
    /* async openLoginModalForIdentity(uid: string, name: string, email: string, phoneNo: string) {
         try {
             const modal = await this.modalController.create({
                 component: HomePage,
                 cssClass: 'custom-modal login-modal',
                 backdropDismiss: false,
                 showBackdrop: true,
                 componentProps: {
                     showIdentity: true,
                     showSignin: false,
                     showPincode: false,
                     showVerifyOtp: false,
                     showPhoneNo: false,
                     userId: uid,
                     btnTxt: 'Save',
                     userName: name,
                     userEmail: email,
                     phoneNo: phoneNo,
                     onlyIdentity: true
                 }
             });
             await modal.present();
         } catch (error) {
             error['location'] = 'shared-service:openLoginModalForIdentity';
             this.logglyService.log(error);
         }
 
     }*/
    // async openLoginModalForIdentity(uid: string, name: string, email: string, phoneNo: string) {
    //     try {
    //         const modal = await this.modalController.create({
    //             component: HomePage,
    //             cssClass: 'custom-modal login-modal',
    //             backdropDismiss: false,
    //             showBackdrop: true,
    //             componentProps: {
    //                 showIdentity: true,
    //                 showSignin: false,
    //                 showPincode: false,
    //                 showVerifyOtp: false,
    //                 showPhoneNo: false,
    //                 userId: uid,
    //                 btnTxt: 'Save',
    //                 userName: name,
    //                 userEmail: email,
    //                 phoneNo: phoneNo,
    //                 onlyIdentity: true
    //             }
    //         });
    //         await modal.present();
    //     } catch (error) {
    //         error['location'] = 'shared-service:openLoginModalForIdentity';
    //         this.logglyService.log(error);
    //     }
    // }
    SharedService.prototype.checkLoginStatus = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, role;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getStorageUid()];
                    case 1:
                        uid = _a.sent();
                        this.utilsService.consoleLog('check login uid', uid);
                        if (!(!uid || uid === undefined)) return [3 /*break*/, 2];
                        this.utilsService.openLoginModal();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.getStorageRole()];
                    case 3:
                        role = _a.sent();
                        if (role === 'admin') {
                            this.presentAlert(msg);
                        }
                        else if (role === 'deliveryAgent') {
                            this.presentAlert(msg);
                        }
                        else {
                            return [2 /*return*/, true];
                        }
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SharedService.prototype.socialShare = function (name, subject, img, link) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.socialSharing.share(name, subject, img, link).then(function () {
                        // console.log('app sharing works!');
                    }).catch(function (err) {
                        // console.log('app sharing not works!', err);
                    });
                }
                catch (error) {
                    error['location'] = 'shared-service:socialShare';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    SharedService.prototype.handleProductSharing = function () {
        /* try {
             this.firebaseDynamicLinks.onDynamicLink()
                 .subscribe((res: any) => {
                     if (res.deepLink.includes('product-details')) {
                         this.navigateToProduct(res.deepLink);
                     } else {
                         this.setReferralObj(res.deepLink);
                     }
                 }), (error: any) => {
                     // console.log(error)
                     this.logglyService.log(error)
                 };
         } catch (error) {
             error['location'] = 'shared-service:handleProductSharing';
             this.logglyService.log(error);
         }*/
    };
    SharedService.prototype.navigateToProduct = function (link) {
        try {
            // console.log('link', link);
            var pid = '';
            for (var index = link.length - 2; index > 0; index--) {
                if (link[index] === '/') {
                    break;
                }
                else {
                    pid += link[index];
                }
            }
            pid = pid.split('').reverse().join('');
            // console.log('pid from dynamic link:', pid);
            this.sharedLinkRouted = true;
            var navigationExtras = {
                state: {
                    productId: pid
                }
            };
            this.navCtrl.navigateRoot(['product-details'], navigationExtras);
        }
        catch (error) {
            error['location'] = 'shared-service:navigateToProduct';
            this.logglyService.log(error);
        }
    };
    SharedService.prototype.setReferralObj = function (link) {
        try {
            // console.log('link', link);
            var userId = '';
            for (var index = link.length - 2; index > 0; index--) {
                if (link[index] === '/') {
                    break;
                }
                else {
                    userId += link[index];
                }
            }
            userId = userId.split('').reverse().join('');
            // console.log('userId from dynamic link:', userId);
            this.referrer.userId = userId;
        }
        catch (error) {
            error['location'] = 'shared-service:setReferralObj';
            this.logglyService.log(error);
        }
    };
    SharedService.prototype.getStorageUid = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.storage.get('uid').then(function (val) {
                                    resolve(val);
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                }
                catch (error) {
                    error['location'] = 'shared-service:getStorageUid';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    SharedService.prototype.getStorageRole = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.storage.get('userRole').then(function (val) {
                                    resolve(val);
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                }
                catch (error) {
                    error['location'] = 'shared-service:getStorageRole';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    SharedService.prototype.getBannersImgPreloader = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.storage.get('bannersImgPreloader').then(function (value) {
                                    resolve(value);
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                }
                catch (error) {
                    error['location'] = 'shared-service:getBannersImgPreloader';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    SharedService.prototype.getCategoriesProductsImgsPreloader = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.storage.get('categoriesProductsImgPreloader').then(function (value) {
                                    resolve(value);
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                }
                catch (error) {
                    error['location'] = 'shared-service:getCategoriesProductsImgsPreloader';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    SharedService.prototype.getBrandsProductsImgsPreloader = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.storage.get('brandsProductsImgPreloader').then(function (value) {
                                    resolve(value);
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                }
                catch (error) {
                    error['location'] = 'shared-service:getBrandsProductsImgsPreloader';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    SharedService.prototype.getSubCategoriesImgsPreloader = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.storage.get('subcategoriesImgPreloader').then(function (value) {
                                    resolve(value);
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                }
                catch (error) {
                    error['location'] = 'shared-service:getSubCategoriesImgsPreloader';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    SharedService.prototype.getStorageDeliverySettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.storage.get('deliverySettings').then(function (val) {
                                    resolve(val);
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                }
                catch (error) {
                    error['location'] = 'shared-service:getStorageDeliverySettings';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    SharedService.prototype.getStorageCheckPincode = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.storage.get('checkPincode').then(function (val) {
                                    resolve(val);
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                }
                catch (error) {
                    error['location'] = 'shared-service:getStorageCheckPincode';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    SharedService.prototype.getStorageRegion = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.storage.get('region').then(function (region) {
                                    resolve(region);
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                }
                catch (error) {
                    error['location'] = 'shared-service:getStorageRegionId';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    SharedService.prototype.presentAlert = function (msg, btn, backdropDismiss, page) {
        if (btn === void 0) { btn = this.labelService.labels['SHARED']['ok']; }
        if (backdropDismiss === void 0) { backdropDismiss = true; }
        if (page === void 0) { page = ''; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            backdropDismiss: backdropDismiss,
                            buttons: [{
                                    text: btn,
                                    handler: function () {
                                        if (page) {
                                            _this.navCtrl.navigateRoot([page]);
                                        }
                                        else {
                                            return;
                                        }
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SharedService.prototype.presentLoading = function (duration, msg) {
        if (duration === void 0) { duration = null; }
        if (msg === void 0) { msg = this.labelService.labels['SHARED']['please_wait']; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: msg,
                                duration: duration,
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SharedService.prototype.presentToast = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: msg,
                            duration: 2000
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    SharedService.prototype.checkLimitedTimeDeal = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var discount, uid_1, index, currentTime, startDate, startTime, endDate, endTime, startDateTime, endDateTime, diff, duration, dealTime;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(data.hasOwnProperty('deal') && data.deal.isAllowed)) return [3 /*break*/, 3];
                        discount = data.deal.discount;
                        if (!('specificUsers' in data.deal && data.deal.specificUsers.active && data.deal.specificUsers.users && data.deal.specificUsers.users.length)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getStorageUid()];
                    case 1:
                        uid_1 = _a.sent();
                        index = data.deal.specificUsers.users.findIndex(function (u) { return u.id === uid_1; });
                        if (index === -1) {
                            return [2 /*return*/, { dealTime: null, discount: null }];
                        }
                        else {
                            discount = data.deal.specificUsers.users[index].discount;
                        }
                        _a.label = 2;
                    case 2:
                        currentTime = moment();
                        startDate = moment(data.deal.start.date).format('YYYY-MM-DD');
                        startTime = moment(data.deal.start.time).format('HH:mm');
                        endDate = moment(data.deal.end.date).format('YYYY-MM-DD');
                        endTime = moment(data.deal.end.time).format('HH:mm');
                        startDateTime = moment(startDate + " " + startTime);
                        endDateTime = moment(endDate + " " + endTime);
                        if (moment(currentTime).isBetween(startDateTime, endDateTime)) {
                            diff = moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(currentTime, 'DD/MM/YYYY HH:mm:ss'));
                            duration = moment.duration(diff);
                            dealTime = Math.floor(duration.asHours()) + moment.utc(diff).format(":mm:ss");
                            //console.log('dealTime', dealTime);
                            return [2 /*return*/, { dealTime: dealTime, discount: discount }];
                        }
                        else {
                            //console.log('not between');
                            return [2 /*return*/, { dealTime: null, discount: null }];
                        }
                        return [3 /*break*/, 4];
                    case 3: return [2 /*return*/, { dealTime: null, discount: null }];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SharedService.prototype.getStarColor = function (rating) {
        if (rating >= 3) {
            return '#20c020';
        }
        else if (rating < 3 && rating >= 2) {
            return '#FF9F00';
        }
        else {
            return '#ff6161';
        }
    };
    SharedService.prototype.getUserCartProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var uid, cartRef, cartProducts, err_1;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 4, , 5]);
                                    return [4 /*yield*/, this.getStorageUid()];
                                case 1:
                                    uid = _a.sent();
                                    if (!(uid && uid !== undefined)) return [3 /*break*/, 3];
                                    cartRef = this.afs.collection('users').doc(uid).collection('cart');
                                    return [4 /*yield*/, cartRef.snapshotChanges().pipe(map(function (snaps) { return convertSnaps(snaps); })).pipe(first()).toPromise()];
                                case 2:
                                    cartProducts = _a.sent();
                                    resolve(cartProducts);
                                    _a.label = 3;
                                case 3: return [3 /*break*/, 5];
                                case 4:
                                    err_1 = _a.sent();
                                    console.dir(err_1);
                                    err_1['location'] = 'shared-service:getUserCartProducts';
                                    this.logglyService.log(err_1);
                                    return [3 /*break*/, 5];
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SharedService.prototype.checkRegionIdForApi = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var region, role, isMultiRegion, isMultiVendor, dbMultiRegion, userRegion;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        region = { regionId: '', vendorId: '' };
                                        return [4 /*yield*/, this.getStorageRole()];
                                    case 1:
                                        role = _a.sent();
                                        if (!(role !== 'admin')) return [3 /*break*/, 5];
                                        isMultiRegion = this.configService.environment.multiRegion;
                                        isMultiVendor = this.configService.environment.multiVendor;
                                        if (!isMultiRegion) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this.multiRegionService.getActiveStatus('service')];
                                    case 2:
                                        dbMultiRegion = _a.sent();
                                        isMultiRegion = dbMultiRegion && dbMultiRegion.active;
                                        _a.label = 3;
                                    case 3:
                                        if (!isMultiRegion) return [3 /*break*/, 5];
                                        return [4 /*yield*/, this.getStorageRegion()];
                                    case 4:
                                        userRegion = _a.sent();
                                        if (userRegion) {
                                            region.regionId = userRegion.id;
                                            // if(userRegion.vendorId) {
                                            //     const vendorSettings: any = await this.vendorService.getActiveStatus('service');
                                            //     if(isMultiVendor && vendorSettings && vendorSettings.active && vendorSettings.oneVendorPerRegion) {
                                            //         const vendor: any = await this.vendorService.getVendorData(userRegion.vendorId);
                                            //         region.vendorId = vendor.active ? userRegion.vendorId : 'inactive';
                                            //     }
                                            // }
                                        }
                                        _a.label = 5;
                                    case 5:
                                        resolve(region);
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                }
                catch (error) {
                    error['location'] = 'shared-service:checkRegionIdForApi';
                    this.logglyService.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    SharedService.prototype.manageExclusiveCategories = function (isCategoryExclusive, categoryId, categoryType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var exclusiveObj;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get('exclusiveObj')];
                    case 1:
                        exclusiveObj = _a.sent();
                        if (!exclusiveObj || exclusiveObj === undefined) {
                            exclusiveObj = { exclusivity: isCategoryExclusive || false, category: isCategoryExclusive || false, categoryId: categoryId, categoryType: categoryType };
                        }
                        else {
                            exclusiveObj['categoryId'] = categoryId;
                            exclusiveObj['categoryType'] = categoryType;
                            if (isCategoryExclusive) {
                                exclusiveObj['category'] = true;
                            }
                            else {
                                exclusiveObj['category'] = false;
                            }
                        }
                        // console.log('exclusiveObj in shared', exclusiveObj);
                        this.storage.set('exclusiveObj', exclusiveObj);
                        return [2 /*return*/];
                }
            });
        });
    };
    SharedService.prototype.prepareProductData = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var wishlistProducts, subRole, userId, cartProducts, product, cartIndex;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        wishlistProducts = data.wishlistProducts, subRole = data.subRole, userId = data.userId, cartProducts = data.cartProducts;
                        product = data.product;
                        cartIndex = cartProducts.findIndex(function (c) { return c.productId === product.id; });
                        if (userId !== '' && (cartIndex !== -1)) {
                            product['cartQty'] = cartProducts[cartIndex].quantity;
                            product['cartId'] = cartProducts[cartIndex].id;
                        }
                        else {
                            product['cartQty'] = 0;
                            product['cartId'] = '';
                        }
                        if (userId !== '' && wishlistProducts && wishlistProducts.length && wishlistProducts.indexOf(product.id) !== -1) {
                            product['isWishlisted'] = true;
                        }
                        else {
                            product['isWishlisted'] = false;
                        }
                        return [4 /*yield*/, this.updateProductPrices(product, subRole)];
                    case 1:
                        product = _a.sent();
                        return [2 /*return*/, product];
                }
            });
        });
    };
    SharedService.prototype.updateProductPrices = function (product, subRole) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deal, retailDiscount_1, retailDiscountType_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.checkLimitedTimeDeal(product)];
                    case 1:
                        deal = _a.sent();
                        if (deal.dealTime) {
                            if (deal.discount > 0) {
                                product.dealActive = true;
                                if (!product.isPriceList) {
                                    product.discountedPrice = product.prodPrice - (product.prodPrice * (deal.discount / 100));
                                }
                                else {
                                    product.priceList.forEach(function (pl) {
                                        pl.discountedPrice = pl.price - (pl.price * (deal.discount / 100));
                                    });
                                }
                            }
                        }
                        if (subRole && subRole === 'retailer' && this.configService.environment.priceForRetail) {
                            retailDiscount_1 = product.retailDiscount ? product.retailDiscount : 0;
                            retailDiscountType_1 = product.retailDiscountType || 'percentage';
                            if (retailDiscount_1) {
                                if (!product.isPriceList) {
                                    if (retailDiscountType_1 === 'percentage') {
                                        product.discountedPrice = product.prodPrice - (product.prodPrice * (retailDiscount_1 / 100));
                                    }
                                    else {
                                        product.discountedPrice = product.prodPrice - retailDiscount_1;
                                    }
                                }
                                else {
                                    product.priceList.forEach(function (pl) {
                                        if (retailDiscountType_1 === 'percentage') {
                                            pl.discountedPrice = pl.price - (pl.price * (retailDiscount_1 / 100));
                                        }
                                        else {
                                            pl.discountedPrice = pl.price - retailDiscount_1;
                                        }
                                    });
                                }
                            }
                        }
                        if (!product.isPriceList) {
                            product.discountedPrice = this.getPriceBasedOnExhangeRate(product.discountedPrice);
                            product.prodPrice = this.getPriceBasedOnExhangeRate(product.prodPrice);
                        }
                        else {
                            product.priceList.forEach(function (pl) {
                                pl.discountedPrice = _this.getPriceBasedOnExhangeRate(pl.discountedPrice);
                                pl.price = _this.getPriceBasedOnExhangeRate(pl.price);
                            });
                        }
                        return [2 /*return*/, product];
                }
            });
        });
    };
    SharedService.prototype.getPriceBasedOnExhangeRate = function (price) {
        return this.sharedNewService.getPriceBasedOnExhangeRate(price);
    };
    SharedService.prototype.openLoginModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: HomePage,
                            backdropDismiss: false,
                            cssClass: "custom-modal login-modal",
                        })];
                    case 1:
                        modal = _a.sent();
                        if (this.configService.environment.appAccessOnApproval) {
                            modal.onDidDismiss().then(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.presentLoading()];
                                        case 1:
                                            _a.sent();
                                            setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                                return tslib_1.__generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            this.loading.dismiss();
                                                            return [4 /*yield*/, this.checkForAppAccesss()];
                                                        case 1:
                                                            _a.sent();
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); }, 2000);
                                            return [2 /*return*/];
                                    }
                                });
                            }); });
                        }
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SharedService.prototype.checkForAppAccesss = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, accessByAdmin;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getStorageUid()];
                    case 1:
                        uid = _a.sent();
                        if (!(!uid || uid === undefined)) return [3 /*break*/, 2];
                        this.loginForAppAccessAlert();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.storage.get('accessByAdmin')];
                    case 3:
                        accessByAdmin = _a.sent();
                        // console.log('accessByAdmin', accessByAdmin);
                        if (!accessByAdmin) {
                            this.accessNotAllowedAlert();
                        }
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SharedService.prototype.loginForAppAccessAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: this.labelService.labels['SHARED']['login_to_continue'],
                            backdropDismiss: false,
                            buttons: [{
                                    text: this.labelService.labels['SHARED']['login'],
                                    handler: function () {
                                        _this.openLoginModal();
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SharedService.prototype.accessNotAllowedAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: this.labelService.labels['SHARED']['access_not_allowed_msg'],
                            backdropDismiss: false
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SharedService.prototype.getFaqData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var data;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    data = JSON.parse(localStorage.getItem("faqPageData") || " {}");
                                    if (!!(Object.keys(data).length)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.afs.collection('settings').doc('contactUs').valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    data = _a.sent();
                                    sessionStorage.setItem("faqPageData", JSON.stringify(data));
                                    _a.label = 2;
                                case 2:
                                    resolve(data);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SharedService.prototype.presentAlertConfirm = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var alert;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.alertController.create({
                                        message: msg,
                                        buttons: [
                                            {
                                                text: this.labelService.labels['SHARED']['no'],
                                                role: 'cancel',
                                                cssClass: 'secondary',
                                                handler: function () {
                                                    //console.log('Confirm Cancel: blah');
                                                    resolve(false);
                                                }
                                            }, {
                                                text: this.labelService.labels['SHARED']['yes'],
                                                handler: function () {
                                                    //console.log('Confirm Okay');
                                                    resolve(true);
                                                }
                                            }
                                        ]
                                    })];
                                case 1:
                                    alert = _a.sent();
                                    return [4 /*yield*/, alert.present()];
                                case 2:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SharedService.prototype.dismissLoading = function () {
        this.loading.dismiss();
    };
    SharedService.prototype.convert24to12 = function (time) {
        return moment(time, ['HH:mm']).format('hh:mm A');
    };
    SharedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedService_Factory() { return new SharedService(i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i3.SocialSharing), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i4.LabelService), i0.ɵɵinject(i5.LogglyLoggerService), i0.ɵɵinject(i6.AngularFirestore), i0.ɵɵinject(i7.ConfigService), i0.ɵɵinject(i1.Events), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.ToastController), i0.ɵɵinject(i8.MultiRegionService), i0.ɵɵinject(i9.UtilsService), i0.ɵɵinject(i10.VendorService), i0.ɵɵinject(i11.MultiCountriesService), i0.ɵɵinject(i12.SharedNewService)); }, token: SharedService, providedIn: "root" });
    return SharedService;
}());
export { SharedService };
