import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { UserService } from 'src/app/services/user/user.service';
var BankDetailsModalPage = /** @class */ (function () {
    function BankDetailsModalPage(modalController, userService, loadingController, labelService) {
        this.modalController = modalController;
        this.userService = userService;
        this.loadingController = loadingController;
        this.labelService = labelService;
        this.bankDetails = {
            name: '',
            accountNumber: '',
            phoneNumber: '',
            ifscCode: '',
            upiId: ''
        };
        this.SHARED_LABELS = {};
    }
    BankDetailsModalPage.prototype.ngOnInit = function () {
    };
    BankDetailsModalPage.prototype.ionViewWillEnter = function () {
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        console.log('userbankDetails', this.userbankDetails);
        if (this.userbankDetails.bankDetails) {
            this.bankDetails.name = this.userbankDetails.bankDetails.name;
            this.bankDetails.accountNumber = this.userbankDetails.bankDetails.accountNumber;
            this.bankDetails.phoneNumber = this.userbankDetails.bankDetails.phoneNumber;
            this.bankDetails.ifscCode = this.userbankDetails.bankDetails.ifscCode;
            this.bankDetails.upiId = this.userbankDetails.bankDetails.upiId;
        }
    };
    BankDetailsModalPage.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: this.SHARED_LABELS['please_wait'],
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BankDetailsModalPage.prototype.closeModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.modalController.dismiss();
                return [2 /*return*/];
            });
        });
    };
    BankDetailsModalPage.prototype.saveBankDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('bankDetails', this.bankDetails);
                        this.presentLoading();
                        return [4 /*yield*/, this.userService.saveBankDetails(this.bankDetails)];
                    case 1:
                        _a.sent();
                        this.loading.dismiss();
                        this.modalController.dismiss('updated');
                        return [2 /*return*/];
                }
            });
        });
    };
    return BankDetailsModalPage;
}());
export { BankDetailsModalPage };
